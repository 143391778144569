// import './pop-up.scss'
import React, { useState, useEffect, useRef } from 'react'
import { toastr } from 'react-redux-toastr'
import { IonSpinner } from '@ionic/react'

import Select from 'react-select'
import {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input'
import { useHistory } from 'react-router'
import { IonInput, IonItem, IonList } from '@ionic/react'
import { connectLoginPopUp } from '../loginPopUp.connect'
import SignUpStep1 from './images/step1.svg'
import SignUpStep2 from './images/step2.svg'
import SignUpStep4 from './images/step4.svg'
import SignUpStep1of3 from './images/step1of3.svg'
import SignUpStep2of3 from './images/step2of3.svg'
import closeLoginHeader from '../../../assets/images/closeLogin.svg'

import rulesAccepted from './images/checkBox.svg'
import eye from '../../../assets/images/eye.svg'
import eyeOff from '../../../assets/images/eyeOff.svg'
import closeLogin from '../../../assets/images/closeCross2.svg'
import promoDefault from '../../../assets/images/promoDefault.png'
import httpAuth from '../../../services/httpAuth'

import { countryOptions } from '../../../helpers/countryList'
import { useSelector } from 'react-redux'
import { OTP_VERIFY } from '../../../config/api.config'

const SignUpTab = ({
  actions,
  recaptchaResponseProps,
  onDidDismiss,
  width,
  signUp,
}) => {
  const [mobile, setMobile] = useState(false)
  const [countryCode, setCountryCode] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [message, setMessage] = useState('')
  const [step, setStep] = useState(5)
  const [isSending, setIsSending] = useState(false)
  const [memberCode, setMemberCode] = useState()
  const [token, setToken] = useState()
  const refRecaptcha = useRef(null)
  const [mobileNumCounter, setMobileNumCounter] = useState(0)
  const [hideFirstPass, setHideFirstPass] = useState(false)
  const [hideSecondPass, setHideSecondPass] = useState(false)
  // const [googleButtonWrapper, setGoogleButtonWrapper] = useState()
  // const [googleLoginWrapper, setGoogleLoginWrapper] = useState()
  const [loading, setLoading] = useState(false)
  const logo = useSelector((state) => state.logo)
  // console.log('step123', step)

  // useEffect(() => {
  //   if (step === 1 || step === 5){
  //     if(width > 1024) {
  //       setStep(1)
  //       console.log('stepDESK', step)
  //     } else {
  //       setStep(5)
  //       console.log('stepMOB', step)
  //     }
  //     console.log('stepEND', step)
  // }
  // }, [width])

  // useEffect(() => {
  //     if(width > 1024) {
  //       setStep(1)
  //       console.log('stepDESK', step)
  //     } else {
  //       setStep(5)
  //       console.log('stepMOB', step)
  //     }
  //     console.log('stepEND', step)
  // }, [])

  // useEffect(() => {
  //   setGoogleLoginWrapper(createGoogleWrapper())
  // }, [])

  const mobileValidation = async (e) => {
    setIsSending(true)

    if (!mobile.length) {
      setMessage('This field is required!')
      setIsSending(false)
      return
    } else if (isValid) {
      // actions.requestRecaptcha()

      setMessage('')
      const recaptchaResponseExecute =
        await recaptchaResponseProps.current.executeAsync()
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        recaptchaResponse: `${recaptchaResponseExecute}`,
      }

      await actions
        .postNumberSignUp(data)
        .then((res) => {
          if (res.status === 200) {
            setIsSending(false)
            setStep(2)
          }
        })
        .catch((e) => {
          setIsSending(false)
          toastr.error('', `${e}`)
        })
    }
  }

  const getBalance = (memberCode) => {
    actions
      .requestBalance(memberCode)
      .then((res) => {
        if (res.data.result.availBalance < 100) {
          // history.push("/deposit")
        }
      })
      .catch((e) => console.log('balance error = ', e))
  }

  const login = async () => {
    try {
      if (!mobile || !password) return
      setIsSending(true)
      const recaptchaResponseExecute =
        await recaptchaResponseProps.current.executeAsync()

      let newUserName = ''
      newUserName = `91_${mobile}`
      await actions
        .requestAuth({
          username: newUserName,
          password,
          recaptchaResponse: recaptchaResponseExecute,
          answer: '',
          token: '',
        })
        .then((res) => {
          getBalance(res.data.result.memberCode)
          onDidDismiss()
          setIsSending(false)
        })
    } catch (e) {
      setIsSending(false)
      //TODO: handle this
      toastr.error('', `${e}`)
      if (refRecaptcha.current) refRecaptcha.current.reset()
    }
  }

  const initialMinute = 0,
    initialSeconds = 59
  const [minutes, setMinutes] = useState(initialMinute)
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    if (step === 2) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval)
          } else {
            setMinutes(minutes - 1)
            setSeconds(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval)
      }
    }
  })

  const validateMobile = (e) => {
    const value = e?.target?.value
    setMobileNumCounter(value.length)
    if (value?.length <= selectedCountry?.length) {
      e.target.value = value?.replace(/[^\d]/g, '')
      setUsername(e.target.value)
    } else {
      e.target.value = username
    }
    if (value?.length === selectedCountry?.length) {
      setIsSendingNew(false)
      setIsSending(false)
      setSeconds(59)
      setSecondsNew(59)
    }
    setOtpVerified(false)

    try {
      // radar start
      if (!mobile.length && value.length === 1 && !otp && !message) {
        const startSignUp = window.srtmCommands.push({
          event: 'track.user.registration',
          payload: { action: 'start' },
        })
      }
      // radar end
    } catch (e) {}

    if (!value) {
      setMessage('This field is required!')
      return
    } else if (value.length !== selectedCountry?.length) {
      setMessage(`Phone number must be ${selectedCountry?.length} digits`)
      setIsValid(false)
    } else if (isPossiblePhoneNumber(`+91${value}`)) {
      setIsValid(true)
      setMobile(value)
      const phoneNumber = parsePhoneNumber(`+91${value}`)
      if (phoneNumber) {
        const code = phoneNumber.countryCallingCode
        setCountryCode(code)
        setMessage('')
        setIsSendingNew(false)
        setOtpSent(false)
      }

      setMessage('')
    }
    // console.log(isPossiblePhoneNumber(`+91${value}`))
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      // submit()
    }
  }

  const [otpMessage, setOtpMessage] = useState('')
  const [isOtpValid, setIsOtpValid] = useState(false)
  const [password, setPassword] = useState(false)
  const [name, setName] = useState('')
  const [confirmPassword, setConfirmPassword] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [isNameValid, setNameValid] = useState(false)
  const [passwordRules, setPasswordRules] = useState({
    length: false,
    specChar: false,
    capitalLetter: false,
    digit: false,
    password: '',
  })
  const [showPasswordRules, setShowPasswordRules] = useState(false)
  const [allRulesChecked, setAllRulesChecked] = useState(false)

  const [isConfirmValid, setConfirmIsValid] = useState(false)

  const [passwordMessage, setPasswordMessage] = useState('')
  const [nameMessage, setNameMessage] = useState('')

  const [confirmMessage, setConfirmMessage] = useState('')
  const [hasErrored, setHasErrored] = useState('')

  const [isRulesAccepted, setIsRulesAccepted] = useState(false)
  const [isRulesAccepted2, setIsRulesAccepted2] = useState(false)

  const ref_input = useRef()
  const [otp, setOtp] = useState()

  const [isPromoValid, setIsPromoValid] = useState(false)
  const [promoMessage, setPromoMessage] = useState('')
  const [isPromoSending, setIsPromoSending] = useState(false)
  const [chosenPromo, setChosenPromo] = useState({})
  const [showDetails, setShowDetails] = useState(false)
  const [promoList, setPromoList] = useState(null)
  const [detailsPromo, setDetailsPromo] = useState(null)
  const [promoCode, setPromoCode] = useState(null)
  const [isSignUpDone, setIsSignUpDone] = useState(false)
  const [refferCode, setRefferCode] = useState();
  // console.log(step);
  // console.log("promoList", signUp);
  // console.log("promoListStore", promoListStore);

  useEffect(() => {
    if ('OTPCredential' in window) {
      window.addEventListener('DOMContentLoaded', (e) => {
        const input = document.querySelector(
          'input[autocomplete="one-time-code"]'
        )
        if (!input) return

        navigator.credentials
          .get({
            otp: { transport: ['sms'] },
          })
          .then((otp) => {
            input.value = otp.code
          })
          .catch((err) => {
            console.log(err)
          })
      })
    }
  }, [])

  const isDisabledButton = () => {
    if (
      isSending ||
      message ||
      otpMessage ||
      confirmMessage ||
      passwordMessage ||
      !isPasswordValid ||
      !isConfirmValid ||
      !isPasswordValid ||
      mobileNumCounter < selectedCountry?.length
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (
      !passwordRules.length ||
      !passwordRules.specChar ||
      !passwordRules.capitalLetter ||
      !passwordRules.digit
    ) {
      if (passwordRules.password) {
        setShowPasswordRules(true)
        setAllRulesChecked(false)
      }
    } else {
      setShowPasswordRules(false)
      setAllRulesChecked(true)
    }
  }, [passwordRules])

  useEffect(() => {
    if (confirmPassword !== password) {
      setConfirmIsValid(false)
      setConfirmMessage('Passwords do not match')
    }
  }, [confirmPassword, password])

  const validatePassword = (password) => {
    // event.preventDefault();
    setIsSending(false)
    // const regex =
    //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,40}$/
    // const regexTesting = regex.test(password)
    setPasswordRules({
      length: password.length >= 5 && password.length <= 16,
      password,
    })

    if (password.length < 5 || password.length > 16) {
      setIsPasswordValid(false)
      setPasswordMessage('Password must be 5-16 characters long');
      setPassword(password)
    } else {
      setIsPasswordValid(true)
      setPasswordMessage('')
      setConfirmIsValid(true)
      setConfirmMessage(true)
      setPassword(password)
    }
  }
  const validateName = (name) => {
    // event.preventDefault();
    setIsSending(false)

    if (name.length < 3) {
      setNameValid(false)
      setNameMessage('Username should be minimus 3 characters')
    } else {
      setNameValid(true)
      setNameMessage('')
      setConfirmMessage(true)
      setName(name)
    }
  }

  const validatePasswordConfirm = (confirmPassword) => {
    setIsSending(false)
    // event.preventDefault();

    // const confirmPassword = event.target.value;

    if (confirmPassword !== password) {
      setConfirmIsValid(false)
      setConfirmMessage('Passwords do not match')
      setConfirmPassword(confirmPassword)
    } else if (password.length < 5 || password.length > 16) {
      setIsPasswordValid(false)
      setPasswordMessage('Password must be 5-16 characters long');
      setConfirmIsValid(false)
      setConfirmMessage('Password must be 5-16 characters long');
      setConfirmPassword(confirmPassword)
    } else {
      setConfirmIsValid(true)
      setConfirmMessage('')
      setPasswordMessage('')
      setIsPasswordValid(true)
      setConfirmPassword(confirmPassword)
    }
  }

  const validateOtp = (otp) => {
    setIsSending(false)
    setOtpMessage('')
    // const otp = event.target.value;
    setOtp(otp)
    if (otp.length != 6) {
      setIsOtpValid(false)
    } else {
      ref_input.current.focus()
      setIsOtpValid(true)
      setOtpMessage('')
    }
  }

  const validatecode = (name) => {
    setIsSending(false)
    setRefferCode(name)
  }

  useEffect(() => {
    let params = new URL(document.location.toString()).searchParams
    let name = params.get('referCode')
    if (name) {
    setRefferCode(name)
    }
  }, [])

  const resendOTP = async () => {
    const dataOTP = {
      orderId: orderId,
    }

    await actions
      .resendOTP(dataOTP)
      .then((res) => {
        if (res.status) {
          toastr.success('', `${res.data.msg}`)
          res?.data?.otp_expiry_time
            ? setSecondsNew(Number(res?.data?.otp_expiry_time))
            : setSeconds(59)
        }
      })
      .catch((e) => toastr.error('', `${e}`))
  }

  const next = async (e) => {
    setIsSending(true)
    if (!otp) {
      setOtpMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      // console.log('OTP Empty')
      setIsSending(false)
    } else if (otp.length != 6) {
      setIsValid(false)
      setHasErrored(true)
      setOtpMessage('OTP you entered is invalid')
      // console.log('OTP invalid')
      setIsSending(false)
    } else if (!password || !confirmPassword) {
      // const regex =
      //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,40}$/
      // const regexTesting = regex.test(password)

      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage(
        password.length < 5 || password.length > 16
          ? 'Password must be between 5 to 16 character'
          : 'This field is required!'
      )
      setConfirmIsValid(false)
      setConfirmMessage('This field is required!')
      setIsSending(false)
    } else if (confirmPassword !== password) {
      setIsValid(false)
      setHasErrored(true)
      setConfirmIsValid(false)
      setConfirmMessage('Passwords do not match')
      setIsSending(false)
    } else if (
      isOtpValid &&
      isValid &&
      isConfirmValid &&
      otp.length === 4 &&
      password === confirmPassword
    ) {
      setIsOtpValid(true)
      setMessage('')
      setHasErrored(false)
      setConfirmMessage('')
      setConfirmIsValid(true)
      setIsValid(true)
      const data = {
        countryCode: `+${countryCode}`,
        mobileNumber: `${mobile}`,
        OTP: `${otp}`,
        password: `${password}`,
        confirmPassword: `${confirmPassword}`,
        atag: '',
      }

      await actions
        .verifyOTP(data)
        .then((res) => {
          
          if (res.status === 200) {
            setMemberCode(res?.data?.result?.memberCode)
            setToken(res.headers.authorization)
            setIsSignUpDone(true)
            setIsSending(false)
          }
          if (promoList?.length) {
            // setPromoList(promoListStore)
            setChosenPromo(promoList[0])
            setStep(3)
          } else {
            setStep(4)
          }
        })
        .catch((e) => {
          setIsSending(false)
          toastr.error('', `${e}`)
        })
    }
  }
  useEffect(() => {
    if (signUp?.length) {
      setPromoList(signUp)
    }
  }, [signUp])

  useEffect(() => {
    try {
      // httpAuth.get('/promotions').then((response) => {
      //   if (response.data?.success) {
      //     if (response.data.result && response.data.result?.length > 0) {
      //       setPromoCode(response.data.result[0].promoCode)
      //       setPromoMessage(response.data.result[0].tagLine)
      //     }
      //   }
      // })
    } catch (e) {
      // const errorMsg = getErrorMessage(e)
      // throw errorMsg
    }
  }, [])

  // useEffect(() => {
  //   setPromoCode(chosenPromo.promoCode)
  // }, [chosenPromo])

  const changeChosenPromo = (item) => {
    if (chosenPromo.tagLine === item.tagLine) {
      setChosenPromo({})
      setPromoCode('')
      setPromoMessage('')
    } else {
      setChosenPromo(item)
    }
  }

  const checkPromo = async () => {
    const data = { promoCode: promoCode }

    await actions
      .postCheckPromo(memberCode, token, data)
      .then((res) => {
        
        if (res.status === 200) {
          setPromoMessage('')
          setIsPromoSending(false)
          res.status === 200 ? setIsPromoValid(true) : setIsPromoValid(false)
          // setTimeout(() => nextStep(), 2000)
          
          setStep(4)
          // actions.setTab(1)
          // onDidDismiss(false)
        }
      })
      .catch((error) => {
        
        toastr.error('', `${error}`)
        setTimeout(() => {
          // setAnimation("")
          setIsPromoSending(false)
        }, 1000)
        setChosenPromo({})
        setPromoCode('')
        setPromoMessage(error?.response?.data?.error)
      })
  }

  const validatePromo = (event) => {
    setPromoCode(event.target.value)
  }

  const nextPromo = (e) => {
    e.preventDefault()
    setIsSending(true)

    if (width > 1024 ? isRulesAccepted : true) {
      if (
        promoList.filter((item) => item.promoCode === promoCode).length >= 1
      ) {
        setPromoMessage('')
        // setAnimation('apply-button_step-one')
        setTimeout(() => {
          setIsPromoSending(true)
          checkPromo()
        }, 500)
      } else {
        setIsSending(false)
        setStep(4)
        // setPromoMessage('Invalid promo code');
      }
    } else {
      toastr.error('', 'Please, accept the terms')
      setIsSending(false)
    }
  }
  const [focus, setFocus] = useState(false)

  const onfocusInput = () => {
    setFocus(true)
  }
  const onBlurInput = () => {
    setFocus(false)
  }

  ////////////////   NEW SignIn ////////////////////
  const [otpSent, setOtpSent] = useState(false)
  const [isSendingNew, setIsSendingNew] = useState(false)
  let [username, setUsername] = useState('')

  const [orderId, setOrderId] = useState('')
  const [otpVerified, setOtpVerified] = useState(false)
  // console.log("orderId", orderId)
  const mobileValidationNew = async (e) => {
    setIsSendingNew(true)

    if (!mobile.length) {
      setMessage('This field is required!')
      setIsSendingNew(false)
      return
    } else {
      // actions.requestRecaptcha()

      setMessage('')
      // const recaptchaResponseExecute =
      //   await recaptchaResponseProps.current.executeAsync()
      // console.log('recaptchaResponse', recaptchaResponseExecute)
      const data = {
        country_code: selectedCountry.value,
        mobile: `${mobile}`,
        // recaptchaResponse: `${recaptchaResponseExecute}`,
      }

      await actions
        .postNumberSignUp(data)
        .then((res) => {
          
          if (res.status === 200) {
            console.log('res', res)
            toastr.success('', `${res?.data?.msg}`)
            setOtpSent(true)
            setOrderId(res?.data?.data?.orderId)
            res?.data?.otp_expiry_time &&
              setSecondsNew(Number(res?.data?.otp_expiry_time))
          }
        })
        .catch((e) => {
          setIsSendingNew(false)
          console.log('err', e)
          toastr.error('', `${e.msg}`)
        })
    }
  }

  const initialMinuteNew = 0,
    initialSecondsNew = 59
  const [minutesNew, setMinutesNew] = useState(initialMinuteNew)
  const [secondsNew, setSecondsNew] = useState(initialSecondsNew)

  useEffect(() => {
    if (otpSent) {
      let myIntervalNew = setInterval(() => {
        if (secondsNew > 0) {
          setSecondsNew(secondsNew - 1)
        }
        if (secondsNew === 0) {
          if (minutesNew === 0) {
            clearInterval(myIntervalNew)
          } else {
            setMinutesNew(minutesNew - 1)
            setSecondsNew(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myIntervalNew)
      }
    }
  })

  // Get country options with flags and codes
  const options = countryOptions.map((country) => ({
    value: country.value, // Use dialCode as value
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={`https://flagcdn.com/w20/${country.flag.toLowerCase()}.png`}
          alt={country.label}
          style={{ marginRight: 10 }}
        />
        {country.label}
      </div>
    ),
    country: country.country,
    length: country.length,
  }))
  const [selectedCountry, setSelectedCountry] = useState(
    options.find((option) => option.value === '+91')
  )

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption)
    setOtpVerified(false)
  }

  const verifyOtp = async (e) => {
    setIsSending(true)
    let erroredFields = false
    if (!mobile) {
      setMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (mobile && !orderId) {
      setMessage('Please send Otp Fisrt!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (!otp) {
      setOtpMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (otp?.length && otp?.length !== 6) {
      setOtpMessage('OTP is invalid!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (!erroredFields) {
      setIsOtpValid(true)
      setMessage('')
      setHasErrored(false)
      setConfirmMessage('')
      setConfirmIsValid(true)
      setIsValid(true)

      const dataForOTP = {
        orderId: orderId,
        otp: otp,
      }
      await actions
        .verifyOTP(dataForOTP)
        .then(async (res) => {
          if (res.status === 200) {
            if (res?.data) {
              res?.data?.status
                ? toastr.success('', res?.data?.msg)
                : toastr.error('', res?.data?.msg || res?.msg)

              if (res?.data?.status) {
                setOtpVerified(true)
              }
              setIsSending(false)
            }
          }
        })
        .catch((e) => {
          setIsSending(false)
          toastr.error('', `${e?.data?.msg || e?.msg}`)
        })
    }
  }

  const nextNew = async (e) => {
    setIsSending(true)
    let erroredFields = false
    if (!name) {
      setNameMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (!mobile) {
      setMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (OTP_VERIFY && mobile && !orderId) {
      setMessage('Please send Otp Fisrt!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (OTP_VERIFY && !otp) {
      setOtpMessage('This field is required!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (OTP_VERIFY && otp?.length && otp?.length !== 6) {
      setOtpMessage('OTP is invalid!')
      setIsValid(false)
      setHasErrored(true)
      setIsSending(false)
      erroredFields = true
    }
    if (!password || password?.length < 5 || password?.length > 16) {
      // const regex =
      //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,40}$/
      // const regexTesting = regex.test(password)
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage(!password ? 'This field is required!' : 'Password required between 5 to 16 character.')
      setIsSending(false)
      erroredFields = true
    }
    if (!confirmPassword) {
      setIsValid(false)
      setHasErrored(true)
      setConfirmIsValid(false)
      setConfirmMessage('This field is required!')
      setIsSending(false)
      erroredFields = true
    }
    if (password.length > 16) {
      setIsValid(false)
      setHasErrored(true)
      setPasswordMessage('Password required between 5 to 16 character.')
      setConfirmIsValid(false)
      setConfirmMessage('Password required between 5 to 16 character.')
      setIsSending(false)
      erroredFields = true
    }
    if (confirmPassword !== password) {
      console.log("confirmPassword", confirmPassword, password)
      setIsValid(false)
      setHasErrored(true)
      setConfirmIsValid(false)
      setConfirmMessage('Passwords do not match')
      setIsSending(false)
      erroredFields = true
    }
    if (
      // isOtpValid &&
      isConfirmValid &&
      // otp.length === 4 &&
      password === confirmPassword &&
      !erroredFields
    ) {
      setIsOtpValid(true)
      setMessage('')
      setHasErrored(false)
      setConfirmMessage('')
      setConfirmIsValid(true)
      setIsValid(true)
      const data = {
        name: `${name}`,
        user_name: `${name.toLowerCase()}`,
        mobile: `${mobile}`,
        password: `${password}`,
      }

      if (refferCode) data.refer_code = `${refferCode.toLowerCase()}`

      await actions
        .registerNew(data)
        .then((res) => {
          
          if (res.status === 200) {
            // setMemberCode(res?.data?.result?.memberCode);
            // setToken(res.headers.authorization)
            if (res?.data) {
              res?.data?.status
                ? toastr.success('', res?.data?.msg)
                : toastr.error('', res?.data?.msg || res?.msg)
            }
            //TODO: add login with data from API
            if (res?.data?.status) {
              let data = {
                user_name: name,
                password: password,
                grant_type: 'password',
              }
              actions.requestAuth(data)
              // toastr.success('', `User created successfully...`)
              setIsSignUpDone(true)
              setIsSending(false)
              onDidDismiss(false)
              try {
                // radar start
                // try{
                //   if (window.location.search) {
                //     let s = "=";
                //     const queryString = window.location.search;
                //     const parameters = new URLSearchParams(queryString);
                //     parameters.forEach((value, key, urlParam) => {
                //       if (value === "WATER") {
                //         window.fbq('track', 'Lead');
                //       }
                //     });
                //   }
                // } catch (e) {
                //   console.log(e);
                // }
  
                // const endSignUp = window.srtmCommands.push({event: "track.user.registration",payload: {action: "complete",userId: res?.data?.result?.memberCode}});
                // console.log('!! endSignUp')
                // radar end
              } catch (e) {
                console.log('in error')
                console.log('error login', e)
                toastr.error('', `${e?.data?.msg || e?.msg}`)
              }
            } else {
              setIsSending(false)
            }
          }
        })
        .catch((e) => {
          setIsSending(false)
          toastr.error('', `${e?.data?.msg || e?.msg}`)
        })
    }
  }

  const createGoogleWrapper = () => {
    // window.google.accounts.id.initialize({
    //   client_id:
    //     '454874373641-aaiuk6rbot84klbhbeduicgddbguvho5.apps.googleusercontent.com',
    //   callback: async (response) => {
    //     const token = response.credential

    //     const recaptchaResponse =
    //       await recaptchaResponseProps.current.executeAsync()
    //     await actions
    //       .requestAuthGoogle({
    //         recaptchaResponse,
    //         token: token,
    //       })
    //       .then((response) => {
    //         window.srtmCommands.push({
    //           event: 'track.user.login',
    //           payload: {
    //             action: 'complete',
    //             userId: response.data.result.memberCode,
    //           },
    //         })
    //         setLoading(false)
    //       })
    //     console.log(response)
    //   },
    //   auto_select: false,
    //   ux_mode: 'popup',
    // })

    // const btn = document.createElement('div')
    // btn.style.display = 'none'
    // btn.classList.add('custom-google-button')
    // document.body.appendChild(btn)

    // window.google.accounts.id.renderButton(btn, {})

    // const googleLoginWrapper = btn.querySelector('div[role=button]')
    // setGoogleButtonWrapper(btn.querySelector('div[role=button]'))
    // return {
    //   click: () => {
    //     googleLoginWrapper.click()
    //   },
    // }
  }

  // const glogin = async () => {
  //   googleButtonWrapper.click()
  //   googleLoginWrapper.click()
  // }

  return (
    <>
      {/* 1 STEP --- MOBILE */}

      {step === 1 && (
        <>
          {width > 1024 && (
            <div className="signUpStep">
              <img src={promoList?.length ? SignUpStep1of3 : SignUpStep1} />
            </div>
          )}
          {width < 1024 && (
            <div
              className="pop-up-content_title"
              onClick={() => onDidDismiss(false)}
            >
              <span className="pop-up-content_title-step">
                {' '}
                Registration with phone number{' '}
              </span>
              <img src={closeLogin} />
            </div>
          )}
          <div
            className={`loginPopUpContainer signUpStep-one ${
              focus ? 'focus' : ''
            }`}
          >
            {width < 1024 && <div className="signUpTitle">Step 2 of 2</div>}
            <IonList class="login-content__list">
              <div className="b2c_login">
                <IonItem>
                  <div className="placeholder">+91</div>
                  <IonInput
                    className="signUpInput"
                    onFocus={onfocusInput}
                    onIonBlur={onBlurInput}
                    formcontrolname="username"
                    placeholder="10 Digit Phone Number"
                    name="username"
                    type="number"
                    autoComplete="username"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 10))
                    }
                    onIonChange={validateMobile}
                    onChange={validateMobile}
                    value={`${username?.replace(' ', '')}`}
                  />
                </IonItem>
                <div className="signUpMessageError">{message}</div>
              </div>
            </IonList>
          </div>

          <div className="pop-up-content__buttons forgot-step two-buttons">
            {width < 1024 && (
              <div onClick={onDidDismiss} className="pushable back-button">
                <div className="front refreshBtn">
                  <span>Back</span>
                </div>
              </div>
            )}
            <button
              style={isSending && width < 1024 ? { background: '#c0bdbd' } : {}}
              onFocus={width < 1024 && !isSending && mobileValidation}
              onClick={!isSending && mobileValidation}
              className="pushable next-button signUpButton"
              disabled={isSending || message}
            >
              <span className="shadow"></span>
              <span className="edge "></span>
              {isSending && width > 1024 ? (
                <>
                  <span
                    style={isSending ? { background: '#c0bdbd' } : {}}
                    className="front refreshBtn login"
                  ></span>
                  <section className="signUpSpinner">
                    <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
              ) : width > 1024 ? (
                <span className="front refreshBtn login">Next</span>
              ) : null}
              {isSending && width < 1024 ? (
                <>
                  <span className="front refreshBtn"></span>
                  <section className="signUpMobileSpinner">
                    <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
              ) : (
                width < 1024 && (
                  <div className="front refreshBtn">
                    <span>Next</span>
                    <div className="next-button-icon"></div>
                  </div>
                )
              )}
            </button>
          </div>
          <div className="loginPopUp-info">
            <span className="loginPopUp-info-text">
              Already have an account?{' '}
            </span>
            &nbsp;
            <span
              onClick={() => actions.setTab(1)}
              className="loginPopUp-info-link"
            >
              Log in
            </span>
          </div>
        </>
      )}

      {/* 2 STEP --- OTP */}

      {step === 2 && (
        <>
          {width > 1024 && (
            <div className="signUpStep">
              <img
                src={promoList?.length ? SignUpStep2of3 : SignUpStep2}
                style={{ marginTop: '24px', marginBottom: '44px' }}
              />
            </div>
          )}
          {width < 1024 && (
            <div
              className="pop-up-content_title"
              onClick={() => onDidDismiss(false)}
            >
              <span className="pop-up-content_title-step"> Step 2 of 2 </span>
              <img src={closeLogin} />
            </div>
          )}
          <div className="loginPopUpContainer forgot-step">
            <div className="OTPText">Enter OTP</div>
            <div className="OTPSubText">
              Please enter four digit OTP which has <br /> been sent to your
              mobile number
            </div>

            <IonList class="login-content__list step-two">
              <div className="b2c_login forgot-pass">
                <div className="login-content__list__input-wrapper">
                  <IonItem>
                    <IonInput
                      className="signUpInput"
                      formcontrolname="otp"
                      placeholder="OTP"
                      name="otp"
                      type="number"
                      pattern="\d*"
                      autocomplete="one-time-code"
                      required
                      onInput={(e) =>
                        (e.target.value = e.target.value
                          .replace(/[^0-9]/g, '')
                          .slice(0, 4))
                      }
                      maxLength={4}
                      onIonChange={(e) => validateOtp(e.target.value)}
                      // onIonChange={(e) => validateMobile(e.target.value)}
                    />
                  </IonItem>
                  <div className="otp-container">
                    {/*<div className={`signUpMessageError ${(otpMessage && otp?.length) ? "otpMessage" : ""}`}>{otpMessage}</div>*/}
                    {otp?.length > 0 && (
                      <div
                        className={`signUpMessageError ${
                          otpMessage && otp?.length ? 'otpMessage' : ''
                        }`}
                      >
                        {otpMessage}
                      </div>
                    )}
                    <div className="resendOTP">
                      <div
                        style={
                          seconds === 0
                            ? { textDecoration: 'underline', cursor: 'pointer' }
                            : null
                        }
                        onClick={() => (seconds === 0 ? setSeconds(59) : null)}
                      >
                        <span
                          className="resendOTP"
                          onClick={seconds === 0 ? resendOTP : null}
                        >
                          Resend OTP
                        </span>
                        {minutes === 0 && seconds === 0 ? null : (
                          <span>
                            {' '}
                            in {minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="login-content__list__input-wrapper">
                  <IonItem>
                    <IonInput
                      required
                      ref={ref_input}
                      formcontrolname="122"
                      placeholder="New Password"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 16))
                      }
                      name="122"
                      className={
                        hideFirstPass
                          ? 'signUpInput signup-password'
                          : 'signUpInput passwordDots'
                      }
                      // type={show ? "text" : "password"}
                      // value={password}
                      autoComplete="off"
                      onKeyDown={onKeyDown}
                      onIonChange={(e) => validatePassword(e.target.value)}
                      // onIonChange={changePass}
                    />
                    <img
                      className="icons"
                      src={hideFirstPass ? eyeOff : eye}
                      onClick={() => setHideFirstPass(!hideFirstPass)}
                    />
                  </IonItem>
                  <div className="signUpMessageError">{passwordMessage}</div>
                </div>
                <div className="login-content__list__input-wrapper">
                  <IonItem className="confirmPasswordInput">
                    <IonInput
                      formcontrolname="122"
                      placeholder="Phone Number"
                      name="122"
                      className={
                        hideSecondPass
                          ? 'signUpInput'
                          : 'signUpInput passwordDots'
                      }
                      // type={show ? "text" : "password"}
                      // value={password}
                      autoComplete="off"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 16))
                      }
                      onKeyDown={onKeyDown}
                      onIonChange={(e) =>
                        validatePasswordConfirm(e.target.value)
                      }
                      // onIonChange={changePass}
                    />
                    <img
                      className="icons"
                      src={hideSecondPass ? eyeOff : eye}
                      onClick={() => setHideSecondPass(!hideSecondPass)}
                    />
                  </IonItem>
                  <div className="signUpMessageError">{confirmMessage}</div>
                </div>
              </div>
            </IonList>
            <div>{message}</div>
          </div>

          <div className="pop-up-content__buttons  forgot-step two-buttons">
            {width < 1024 && (
              <div onClick={() => setStep(1)} className="pushable back-button">
                <div className="front refreshBtn">
                  <span>Back</span>
                </div>
              </div>
            )}
            <button
              onClick={next}
              style={isSending && width < 1024 ? { background: '#c0bdbd' } : {}}
              className="pushable next-button signUpButton"
              disabled={isDisabledButton()}
            >
              <span className="shadow"></span>
              <span className="edge "></span>
              {isSending && width > 1024 ? (
                <>
                  <span
                    style={isSending ? { background: '#c0bdbd' } : {}}
                    className="front refreshBtn login"
                  ></span>
                  <section className="signUpSpinner">
                    <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
              ) : width > 1024 ? (
                <span className="front refreshBtn login">Next</span>
              ) : null}
              {isSending && width < 1024 ? (
                <>
                  <span className="front refreshBtn"></span>
                  <section className="signUpMobileSpinner">
                    <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
              ) : (
                width < 1024 && (
                  <div className="front refreshBtn">
                    <span>Next</span>
                    <div className="next-button-icon"></div>
                  </div>
                )
              )}
            </button>
          </div>
        </>
      )}

      {/* 3 STEP ---PROMOTIONS */}

      {step === 3 && promoList?.length && (
        <>
          {width > 1024 && (
            <div className="signUpStep">
              <img
                src={SignUpStep4}
                style={{ marginTop: '24px', marginBottom: '44px' }}
              />
            </div>
          )}
          {width < 1024 && (
            <>
              <div className="signUpStep-promo-image">
                <img
                  src={
                    (promoList && promoList[0]?.promoImageUrl) || promoDefault
                  }
                />
              </div>
              <div
                className="pop-up-content_title"
                onClick={() => onDidDismiss(false)}
              >
                <span className="pop-up-content_title-step"> Step 2 of 2 </span>
                <img src={closeLogin} />
              </div>
            </>
          )}
          <div className="loginPopUpContainer">
            <div className="stepThreeTitle">Promotions</div>

            <IonItem className="confirmPasswordInput">
              <IonInput
                className="signUpInput"
                placeholder="Enter promo code"
                name="promo"
                autoComplete="off"
                required
                value={promoCode}
                onClick={() => {
                  setChosenPromo({})
                  setPromoCode('')
                  setPromoMessage('')
                }}
                onChange={validatePromo}
                // onIonChange={(e) => validatePasswordConfirm(e.target.value)}
                // onIonChange={changePass}
              />
            </IonItem>

            <div
              className="stepThreeTitle"
              style={{
                fontSize: '12px',
                marginTop: '14px',
                marginBottom: '4px',
              }}
            >
              Available promotions
            </div>

            {promoList &&
              promoList.map((item, index) => (
                <div className="promotionsContainer">
                  <div
                    onClick={() => changeChosenPromo(item)}
                    className={`promo__inputs-select ${
                      chosenPromo?.tagLine === item?.tagLine ? 'active' : ''
                    }`}
                  >
                    <div className="promo__inputs-select-button">
                      <div
                        className={
                          chosenPromo?.tagLine === item?.tagLine
                            ? 'promo__inputs-select-button-circle-active'
                            : 'promo__inputs-select-button-circle'
                        }
                      ></div>
                    </div>
                    <div className="promo__inputs-select-gray">
                      {item?.name}
                    </div>
                    <div>{item?.tagLine}</div>
                    <div>
                      <span
                        onClick={(e) => {
                          e.stopPropagation()
                          actions.setIsDetailedPromo(true)
                          actions.setPromoInfo(item)
                          setDetailsPromo(item?.info)
                          setShowDetails(true)
                        }}
                        className="promo__inputs-select-details"
                      >
                        Details
                      </span>
                    </div>
                  </div>
                </div>
              ))}

            {width > 1024 && (
              <div>
                {isRulesAccepted ? (
                  <img
                    onClick={() => setIsRulesAccepted(!isRulesAccepted)}
                    src={rulesAccepted}
                    style={{ marginRight: '2px' }}
                  />
                ) : (
                  <div
                    onClick={() => setIsRulesAccepted(!isRulesAccepted)}
                    className="rulesEmpty"
                  ></div>
                )}
                <span className="signUpRulesText">
                  I accept the following{' '}
                  <span
                    onClick={() => actions.setIsTermCondition(true)}
                    className="signUpRulesText-terms"
                  >
                    {' '}
                    &nbsp; Terms & Conditions
                  </span>
                  <br /> and confirm that I am 18+
                </span>
              </div>
            )}
          </div>

          <div className="pop-up-content__buttons">
            <button
              // onClick={!isPromoSending && isRulesAccepted ? nextPromo : null}
              style={isSending && width < 1024 ? { background: '#c0bdbd' } : {}}
              onClick={nextPromo}
              className="pushable next-button signUpButton"
              // disabled={isDisabledButton2()}
            >
              <span className="shadow"></span>
              <span className="edge "></span>
              {isSending && width > 1024 ? (
                <>
                  <span
                    style={isSending ? { background: '#c0bdbd' } : {}}
                    className="front refreshBtn login"
                  ></span>
                  <section className="signUpSpinner">
                    <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
              ) : isSending && width < 1024 ? (
                <>
                  <span className="front refreshBtn"></span>
                  <section className="signUpMobileSpinner">
                    <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
              ) : (
                <span
                  className={`front refreshBtn ${width > 1024 && 'signUp'}`}
                >
                  Confirm
                </span>
              )}
              {/* <span className={`front refreshBtn ${width > 1024 && "signUp"}`}>Confirm</span> */}
            </button>
          </div>
        </>
      )}

      {/* 4 STEP --- All Set */}

      {step === 4 && (
        <>
          {width > 1024 && (
            <div className="signUpStep">
              <img
                src={SignUpStep2}
                style={{ marginTop: '24px', marginBottom: '44px' }}
              />
            </div>
          )}
          {width < 1024 && (
            <div
              className="pop-up-content_title"
              onClick={() => onDidDismiss(false)}
            >
              <span className="pop-up-content_title-step"> Step 2 of 2 </span>
              <img src={closeLogin} />
            </div>
          )}
          <div className="loginPopUpContainer">
            <div className="stepThreeTitle">You’re all set</div>
            <div className="allSetImg">{/*<img src={allSet}></img>*/}</div>
            <div
              style={{ cursor: 'pointer', textAlign: '' }}
              onClick={() => setIsRulesAccepted2(!isRulesAccepted2)}
            >
              {isRulesAccepted2 ? (
                <img
                  onClick={() => setIsRulesAccepted2(!isRulesAccepted2)}
                  src={rulesAccepted}
                  style={{ marginRight: '2px', cursor: 'pointer' }}
                />
              ) : (
                <div
                  onClick={() => setIsRulesAccepted2(!isRulesAccepted2)}
                  className="rulesEmpty"
                ></div>
              )}
              <span className="signUpRulesText">
                I accept the {width > 1024 && `following`}{' '}
                <span
                  onClick={() => actions.setIsTermCondition(true)}
                  className="signUpRulesText-terms"
                >
                  {' '}
                  &nbsp; Terms & Conditions
                </span>
                <br /> and confirm that I am 18+
              </span>
            </div>
          </div>

          <div className="pop-up-content__buttons forgot-step">
            {/*{width < 1024 &&*/}
            {/*  <div onClick={() => setStep(1)} className="pushable back-button">*/}
            {/*    <div className="front refreshBtn ">*/}
            {/*      <span>Back</span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*}*/}
            <button
              disabled={isDisabledButton()}
              onClick={() => {
                if (isRulesAccepted2) {
                  login()
                } else {
                  toastr.error('', 'Please, accept the terms')
                }
              }}
              className="pushable next-button signUpButton"
              // disabled={!isRulesAccepted2}
            >
              <span className="shadow"></span>
              <span className="edge "></span>

              {isSending && width < 1024 ? (
                <>
                  <span className="front refreshBtn"></span>
                  <section className="signUpMobileSpinner">
                    <IonSpinner className="spinnerLines" name="lines" />
                  </section>
                </>
              ) : width > 1024 ? (
                <span className="front refreshBtn signUp">Confirm</span>
              ) : (
                <div className="front refreshBtn">
                  <span>Confirm</span>
                </div>
              )}

              {/*{width > 1024 && <span className="front refreshBtn signUp">Confirm</span>}*/}
              {/*{width < 1024 && <div className="front refreshBtn">*/}
              {/*  <span>Confirm</span>*/}
              {/*</div>}*/}
            </button>
          </div>
        </>
      )}

      {step === 5 && (
        <>
          <div className="signUpNew_wrapper">
            {/* <div className="signUpNew_closeButton" onClick={() => onDidDismiss(false)}>
          </div> */}
            <div className="signUpNew">
              <div
                className="signUpNew_header"
                style={{
                  backgroundImage: `url(${logo})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: '200px',
                  height: '52px',
                }}
              ></div>
              <img
                className="signUpNew-closeIcon"
                src={closeLoginHeader}
                onClick={() => onDidDismiss(false)}
              />
              {promoMessage !== '' && <span>{promoMessage}</span>}
            </div>
            <div className="signUpNew_header__title">Register</div>

            {/* {CONFIG.isWhatsappOnSignUp === true &&
            <>

              <div className="signUpNew_whattsUp">
                <div className="signUpNew_whattsUp_title">Get your ready-made ID from Whatsapp</div>
                <div className = "signUpNew_icons">
                  <img src={instantDeposit} alt="" />
                  <span>Diamond upgrade</span>
                  <img src={support} alt="" />
                  <span>1 to 1 customer support</span>
                  <img src={nonStop} alt="" />
                  <span>24/7 instant withdrawal</span>

                </div>

                <div className="signUpNew_whattsUp_button"> */}
            {/*window.open(`${CONFIG.whatsappLink}`)*/}

            {/* <a href={`https://wa.me/8888888888`}
                target="_blank"
                className="landing-footer-social-button whatsapp">
                <span>8888888888</span>
              </a> */}
            {/* <div className="signUpNew_whattsUp_button-icon"/>
                  <div className="signUpNew_whattsUp_button-number blinking">WHATSAPP NOW</div>
                </div>
              </div>

              <div className="signUpNew-separator">
                <div className="signUpNew-separator-rightLine"></div>
                <div className="signUpNew-separator-or">OR</div>
                <div className="signUpNew-separator-leftLine"></div>
              </div>

            </>
          } */}

            <div className="loginPopUpContainer forgot-step">
              <div className="signUpNew_inputContainer phone">
                <input
                  id="suka"
                  required
                  ref={ref_input}
                  formcontrolname="122"
                  placeholder="Enter username"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 16))
                  }
                  name="username"
                  className="signUpNew_inputContainer-input phone"
                  autoComplete="off"
                  onKeyDown={onKeyDown}
                  onChange={(e) => validateName(e.target.value)}
                  onFocus={() => null}
                  onBlur={() => setShowPasswordRules(false)}
                />
                {/* <div
              className={`${hideFirstPass ? 'signUpNew_icons eyeOff' : 'signUpNew_icons eye'} `}
              onClick={() => setHideFirstPass(!hideFirstPass)}
              /> */}
                {/* <img
                className="icons"
                src={hideFirstPass ? eyeOff : eye}
                onClick={() => setHideFirstPass(!hideFirstPass)}
              /> */}
                <div className="signUpNew_inputContainer_row">
                  <div className="signUpNew_inputContainer_row-error">
                    {nameMessage}
                  </div>
                </div>
              </div>

              <div
                className="signUpNew_inputContainer"
                style={{ marginBottom: '0' }}
              >
                <div className="signUpNew_inputContainer-input-wrapper__number">
                  {/* <div className="signUpNew__placeholder">+91</div> */}
                  <div className="signUpNew_inputContainer-input-wrapper__number__country">
                    <Select
                      options={options}
                      onChange={handleCountryChange}
                      placeholder="Select a country"
                      defaultValue={options.find(
                        (option) => option.value === '+91'
                      )} // Default to India
                      isSearchable={true}
                      filterOption={(option, inputValue) => 
                        option.data?.country?.toLowerCase().includes(inputValue.toLowerCase()) || 
                        option.data?.value?.toLowerCase().includes(inputValue.toLowerCase()) // Custom filter logic
                      }
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          display: 'flex',
                          alignItems: 'center',
                          width: '150px!important',
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          display: 'flex',
                          alignItems: 'center',
                        }),
                      }}
                    />
                  </div>
                  <input
                    className="signUpNew_inputContainer-input phone-counrty"
                    onFocus={onfocusInput}
                    onBlur={onBlurInput}
                    formcontrolname="username"
                    placeholder=" Whatsapp Number"
                    name="number"
                    type="number"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(
                        0,
                        selectedCountry?.length
                      ))
                    }
                    autoComplete="username"
                    onIonChange={validateMobile}
                    onChange={validateMobile}
                    value={`${username?.replace(' ', '')}`}
                  />
                </div>
                <div className="signUpNew_inputContainer_row">
                  <div className="signUpNew_inputContainer_row-error">
                    {message || mobile ? '' : OTP_VERIFY ? 'OTP will be sent on whatsapp.' : ''}
                  </div>
                  <span
                    className={`signUpNew_inputContainer_row-counter ${
                      mobileNumCounter === selectedCountry?.length &&
                      'counterDone'
                    }`}
                  >
                    {mobileNumCounter}/{selectedCountry?.length}
                  </span>
                </div>
                {OTP_VERIFY && <div className="signUpNew_inputContainer_otp">
                  <div
                    className={`signUpNew_inputContainer_otp-button ${
                      otpSent || mobileNumCounter !== selectedCountry?.length
                        ? 'otpButtonDisabled'
                        : ''
                    }`}
                    onClick={() => (!otpSent ? mobileValidationNew() : null)}
                  >
                    Get OTP
                  </div>
                </div>}
              </div>

              {OTP_VERIFY && <div className="signUpNew_inputContainer">
                <input
                  className="signUpNew_inputContainer-input otp"
                  formcontrolname="otp"
                  placeholder="OTP"
                  name="otp"
                  type="number"
                  pattern="\d*"
                  autoComplete="one-time-code"
                  onInput={(e) =>
                    (e.target.value = e.target.value
                      .replace(/[^0-9]/g, '')
                      .slice(0, 6))
                  }
                  required
                  maxLength={6}
                  onChange={(e) => validateOtp(e.target.value)}
                />
                <div className="otp-container">
                  {/* {otp?.length > 0 && ( */}
                  <div
                    className={`signUpMessageError ${
                      otpMessage ? 'otpMessage' : ''
                    }`}
                  >
                    {otpMessage}
                  </div>
                  {/* )} */}
                  {isSendingNew && !otpVerified && (
                    <div className="signUpNew_inputContainer-resendOTP">
                      <div
                        style={
                          secondsNew === 0
                            ? { textDecoration: 'underline', cursor: 'pointer' }
                            : null
                        }
                        onClick={() =>
                          secondsNew === 0 ? setSecondsNew(59) : null
                        }
                      >
                        <span
                          className="signUpNew_inputContainer-resendOTP"
                          onClick={secondsNew === 0 ? resendOTP : null}
                        >
                          Resend OTP
                        </span>
                        {minutesNew === 0 && secondsNew === 0 ? null : (
                          <span>
                            {' '}
                            in {minutesNew}:
                            {secondsNew < 10 ? `0${secondsNew}` : secondsNew}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="signUpNew_inputContainer_otp">
                  <div
                    className={`signUpNew_inputContainer_otp-button ${
                      otpSent && otp?.length === 6 && !otpVerified
                        ? ''
                        : 'otpButtonDisabled topPosition'
                    }`}
                    onClick={() =>
                      otpSent && otp?.length === 6 && !otpVerified
                        ? verifyOtp()
                        : null
                    }
                  >
                    Verify OTP
                  </div>
                </div>
              </div> }

              <div className="signUpNew_inputContainer lock">
                <input
                  id="suka"
                  required
                  ref={ref_input}
                  formcontrolname="122"
                  placeholder="Password"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 16))
                  }
                  name="122"
                  className={
                    hideFirstPass
                      ? 'signUpNew_inputContainer-input signup-password'
                      : 'signUpNew_inputContainer-input passwordDots'
                  }
                  autoComplete="off"
                  onKeyDown={onKeyDown}
                  onChange={(e) => validatePassword(e.target.value)}
                  onFocus={() =>
                    !allRulesChecked ? setShowPasswordRules(true) : null
                  }
                  onBlur={() => setShowPasswordRules(false)}
                />
                <div
                  className={`${
                    hideFirstPass
                      ? 'signUpNew_icons eyeOff'
                      : 'signUpNew_icons eye'
                  } `}
                  onClick={() => setHideFirstPass(!hideFirstPass)}
                />
                {/* <img
                className="icons"
                src={hideFirstPass ? eyeOff : eye}
                onClick={() => setHideFirstPass(!hideFirstPass)}
              /> */}
                <div className="signUpNew_inputContainer_row">
                  <div className="signUpNew_inputContainer_row-error">
                    {passwordMessage}
                  </div>
                </div>
              </div>

              <div className="signUpNew_inputContainer lock">
                <input
                  formcontrolname="122"
                  placeholder="Confirm Password"
                  name="122"
                  className={
                    hideSecondPass
                      ? 'signUpNew_inputContainer-input signup-password'
                      : 'signUpNew_inputContainer-input passwordDots'
                  }
                  autoComplete="off"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 16))
                  }
                  onKeyDown={onKeyDown}
                  onChange={(e) => validatePasswordConfirm(e.target.value)}
                />
                <div
                  className={`${
                    hideSecondPass
                      ? 'signUpNew_icons eyeOff'
                      : 'signUpNew_icons eye'
                  } `}
                  onClick={() => setHideSecondPass(!hideSecondPass)}
                />
                {/* <img
                className="icons"
                src={hideSecondPass ? eyeOff : eye}
                onClick={() => setHideSecondPass(!hideSecondPass)}
              /> */}
                <div className="signUpNew_inputContainer_row">
                  <div className="signUpNew_inputContainer_row-error">
                    {confirmMessage}
                  </div>
                </div>
              </div>

              <div className="signUpNew_inputContainer phone">
                <input
                  id="suka"
                  ref={ref_input}
                  formcontrolname="122"
                  placeholder="Enter Refer Code"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 16))
                  }
                  value={refferCode}
                  name="refercode"
                  className="signUpNew_inputContainer-input phone"
                  autoComplete="off"
                  onKeyDown={onKeyDown}
                  onChange={(e) => validatecode(e.target.value)}
                  onFocus={() => null}
                  onBlur={() => setShowPasswordRules(false)}
                />
              </div>
            </div>

            <div className="pop-up-content__buttons-first">
              {/* {width < 1024 &&
              <div onClick={() => onDidDismiss(false)} className="pushable back-button">
                <div className="front refreshBtn">
                  <span>{t('loginAndRegPopUp.forgotPasswordTab.back')}</span>
                </div>
              </div>
            } */}
              <button
                onClick={nextNew}
                style={
                  isSending && width < 1024
                    ? { background: '#c0bdbd' }
                    : { marginBottom: '10px' }
                }
                className={`pop-up-content__buttons-first__btn ${
                  !otpVerified && OTP_VERIFY && 'otpButtonDisabled'
                }`}
                // disabled={isDisabledButton()}
              >
                <span className="shadow"></span>
                <span className="edge "></span>
                {isSending && width > 1024 ? (
                  <>
                    <span
                      style={isSending ? { background: '#c0bdbd' } : {}}
                      className="front refreshBtn login"
                    ></span>
                    <section className="signUpSpinner">
                      <IonSpinner className="spinnerLines" name="lines" />
                    </section>
                  </>
                ) : width > 1024 ? (
                  <span className="front refreshBtn login">Register</span>
                ) : null}
                {isSending && width < 1024 ? (
                  <>
                    <span className="front refreshBtn"></span>
                    <section className="signUpMobileSpinner">
                      <IonSpinner className="spinnerLines" name="lines" />
                    </section>
                  </>
                ) : (
                  width < 1024 && (
                    <div className="front refreshBtn">
                      <span>Register</span>
                      <div className="next-button-icon"></div>
                    </div>
                  )
                )}
              </button>
              {/* <div className="signUpNew-or">Or Register with</div> */}

              {/* <div className='registerWith-content-btns'>
                <div className="register-button" onClick={() => glogin()}>
                  <img className="icon" src={GoogleIcon} alt="" />
                  <span className="register-button-link">Google</span>
                </div>
                <div className="register-button" onClick={() =>
                  // actions.setTab(2)
                  // window.open(CONFIG.whatsappLink)
                  window.location.href = `https://lotus365.authlink.me/?redirectUri=http://${window.location.host}`

                }>
                  <span >New user? </span>
                  <span className="register-button-link">{t('loginAndRegPopUp.registerNow')}</span>
                  <img className="icon" src={WhatsappIcon} alt="" />
                  <span className="register-button-link">Whatsapp</span>
                </div>
              </div> */}
            </div>

            <div className="signUpNew_info">
              <span className="signUpNew_info-text">
                Already have an account?{' '}
              </span>
              &nbsp;
              <span
                onClick={() => actions.setTab(1)}
                className="signUpNew_info-link"
              >
                Log in
              </span>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default connectLoginPopUp()(SignUpTab)
